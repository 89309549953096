import React, { useState, useEffect, setState } from 'react'
import {useHistory, Link} from 'react-router-dom'
import { Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { notification } from 'antd'
import { useForm, Controller} from 'react-hook-form'
import { OutlinedButton, FilledBrightButton } from '@oneblinktech-org/helios-base'
import states from '../../fakeData/state'
import { CUSTOMER_ROLE_TYPE, getCookie, SUPPORT_URL } from '../../helpers/globals'
import {
    debouncedCreateCustomer, debouncedEditCustomer
} from '../../store/job/actions'
import { ApiSpin } from '@oneblinktech-org/helios-base';

const CustomerModal = (props) => {
    const [customer, setCustomer] = useState({})
    const { customerData, isEdit } = props
    const { register, handleSubmit, setValue, getValues, control, watch, formState: { errors } } = useForm({
        defaultValues: {
            custom_state: customerData?.state ?? '',
            email: customerData?.email,
            firstname: customerData?.firstname,
            lastname: customerData?.lastname,
            company_name: customerData?.helios_user_setting?.company_name ? customerData?.helios_user_setting?.company_name : '',
            address: customerData?.address,
            city: customerData?.city,
            state: customerData?.custom_state,
            phone_number: customerData?.helios_user_setting?.phone_number,
            alternate_phone: customerData?.helios_user_setting?.alternate_phone ? customerData?.helios_user_setting?.alternate_phone : '',
            type_id: customerData?.type_id,
        }
    })

    const [isAlertLoading, setAlertLoading] = useState(false)
    const [isError, setISError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect (() => {
        if(props.customer.message && !props.customer.modal) {
            setCustomer({})
            props.closeModal()
        }
    }, [props.customer.modal])

    useEffect(() => {
        const { loading, errors, isSuccess } = props.customer
        setAlertLoading(loading)
        setIsSuccess(isSuccess)
        setISError(isSuccess === false)
        setErrorMsg(JSON.stringify(errors))
    }, [props.customer.loading])

    const handleInputChange = (e) => {
        const target = e.target;
        let tmp = []
        tmp[target.name] = target.value
        setCustomer({...customer, ...tmp})
    }

    const handleAlternatePhoneChange = (e) => {
        setCustomer({...customer, alternate_phone: e.value})
    }

    const onCancelClick = () => {
        props.closeModal()
    }

    const watchAllFields = watch()

    const {ref: emailRef, ...emailRest} = register("email", {required: false})
    const {ref: firstnameRef, ...firstnameRest} = register("firstname", {required: true})
    const {ref: lastnameRef, ...lastnameRest} = register("lastname", {required: true})
    const {ref: addressRef, ...addressRest} = register("address", {required: true})
    const {ref: cityRef, ...cityRest} = register("city", {required: true})
    const {ref: companyRef, ...companyRest } = register("company_name", { required: false })
    const {ref: customStateRef, ...customStateRest} = register("custom_state", {required: true})

    const phoneNumber = watch('phone_number', '', {required: true})
    const alternateNumber = watch('alternate_phone', '', { required: false })

    const onSubmitCustomer = (data) => {
        try {
            let payload = {
                email: data?.email,
                firstname: data?.firstname,
                lastname: data?.lastname,
                company_name: data?.company_name ? data?.company_name : '',
                address: data?.address,
                city: data?.city,
                state: data?.custom_state,
                phone_number: data?.phone_number,
                alternate_phone: data?.alternate_phone || '',
                role: CUSTOMER_ROLE_TYPE,
                organization_id: getCookie('active_organization_id'),
            }
            if (isEdit)
                props.editCustomer(payload, customerData?.id)
            else
                props.submitCustomer(payload)
        } catch (error) {
            console.log(error)
        }
    }

    const resetData = () => {
        setValue('email', '')
        setValue('firstname', '')
        setValue('lastname', '')
        setValue('address', '')
        setValue('city', '')
        setValue('custom_state', '')
        setValue('phone_number', '')
    }

    return (
        <div>
            {props.isModalOpen &&
                <ApiSpin loading={isAlertLoading} error={isError} success={isSuccess} error_txt={errorMsg} support_url={SUPPORT_URL} />
            }
            <form onSubmit={handleSubmit(onSubmitCustomer)} autoComplete="off">
                <div className="p-4">
                    {
                        (props?.customer?.errors && props?.customer?.errors?.data?.errors != null && Object.keys(props?.customer?.errors?.data?.errors).length > 0) &&
                        Object.keys(props?.customer?.errors?.data?.errors).map((objKey, key) => {
                            return (
                                <Alert color="danger" key={key}><div>{props?.customer?.errors?.data?.errors[objKey]}</div></Alert>
                            )
                        })
                    }
                    <div className="divider-label">
                        <h6><span className="pr-2">Customer Details</span></h6>
                    </div>
                    <div className="row mt-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-12 custom-form-wrap">
                            <Controller
                                name={"email"}
                                control={control}
                                innerRef={register("email", { required: false })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="select-box-bottom-line"
                                            autoComplete="email"
                                            innerRef={ emailRef }
                                            { ...emailRest }
                                        />
                                    )
                                }}
                            />
                            {errors.email && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('email') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="email">Email</label>
                        </FormGroup>
                    </div>
                    <div className="row mt-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-6 custom-form-wrap">
                            <Controller
                                name={"firstname"}
                                control={control}
                                innerRef={register("firstname", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            className="select-box-bottom-line"
                                            autoComplete="firstname"
                                            innerRef={ firstnameRef }
                                            { ...firstnameRest }
                                        />
                                    )
                                }}
                            />
                            {errors.firstname && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('firstname') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="firstname">First Name*</label>
                        </FormGroup>
                        <FormGroup className="gray-bg col-12 col-md-6 custom-form-wrap">
                            <Controller
                                name={"lastname"}
                                control={control}
                                innerRef={register("lastname", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            className="select-box-bottom-line"
                                            autoComplete="lastname"
                                            innerRef={ lastnameRef }
                                            { ...lastnameRest }
                                        />
                                    )
                                }}
                            />
                            {errors.lastname && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('lastname') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="lastname">Last Name*</label>
                        </FormGroup>
                    </div>
                    <div className="row mt-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-12 custom-form-wrap">
                            <Controller
                                name={"company_name"}
                                control={control}
                                innerRef={register("company_name", { required: false })}
                                render={({ value }) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="company_name"
                                            id="company_name"
                                            autoComplete='company_name'
                                            className="select-box-bottom-line"
                                            value={value}
                                            innerRef={companyRef}
                                            {...companyRest}
                                        />
                                    )
                                }}
                            />
                            {errors.company_name && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('company_name') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="company_name">Company Name</label>
                        </FormGroup>
                    </div>
                    <div className="row mt-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-4 custom-form-wrap">
                            <Controller
                                name={"address"}
                                control={control}
                                innerRef={register("address", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="address"
                                            id="address"
                                            className="select-box-bottom-line"
                                            autoComplete="address"
                                            innerRef={ addressRef }
                                            { ...addressRest }
                                        />
                                    )
                                }}
                            />
                            {errors.address && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('address') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="address">Address*</label>
                        </FormGroup>
                        <FormGroup className="gray-bg col-12 col-md-4 custom-form-wrap">
                            <Controller
                                name={"city"}
                                control={control}
                                innerRef={register("city", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="city"
                                            id="city"
                                            className="select-box-bottom-line"
                                            autoComplete="city"
                                            innerRef={ cityRef }
                                            { ...cityRest }
                                        />
                                    )
                                }}
                            />
                            {errors.city && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('city') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="city">City*</label>
                        </FormGroup>
                        <FormGroup className="col-12 col-md-4 custom-form-wrap">
                            <Input
                                type="select"
                                name="custom_state"
                                id="custom_state"
                                className="select-box-blue-round gray-bg"
                                autoComplete="select"
                                bsSize="default"
                                innerRef={customStateRef}
                                {...customStateRest}
                            >
                                {
                                    states.map((item, index) => {
                                        return (
                                            <option value={item.name} key={index}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            {errors.custom_state && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('custom_state') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="custom_state">State</label>
                        </FormGroup>
                    </div>
                    <div className="row mt-3 borde-gray-bottom pb-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-4 custom-form-wrap">
                            <Controller
                                name={"phone_number"}
                                control={control}
                                innerRef={register("phone_number", { required: true })}
                                render={() => {
                                    return (
                                        <NumberFormat
                                            className="select-box-bottom-line form-control"
                                            placeholder=""
                                            format="(###) ###-####"
                                            name="phone_number"
                                            id="phone_number"
                                            autoComplete="phone_number"
                                            mask="_"
                                            onValueChange={(value) => setValue("phone_number", value.value)}
                                            value={getValues('phone_number') || ''}
                                        />
                                    )
                                }}
                            />
                            {errors.phone_number && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('phone_number') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="phone_number">Main Phone*</label>
                        </FormGroup>
                        <FormGroup className="gray-bg col-12 col-md-4 custom-form-wrap">
                            <Controller
                                name={"alternate_phone"}
                                control={control}
                                innerRef={register("alternate_phone", { required: false })}
                                render={({ }) => {
                                    return (
                                        <NumberFormat
                                            className="select-box-bottom-line form-control"
                                            placeholder=""
                                            format="(###) ###-####"
                                            name="alternate_phone"
                                            id="alternate_phone"
                                            autoComplete="alternate_phone"
                                            mask="_"
                                            onValueChange={(value) => setValue("alternate_phone", value.value)}
                                            value={getValues('alternate_phone') || ''}
                                        />

                                    )
                                }}
                            />
                            {errors.alternate_phone && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('alternate_phone') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="alternate_phone">Alternate Phone</label>
                        </FormGroup>
                    </div>
                </div>
                <ModalFooter>
                    <OutlinedButton
                        disabled={props.customer.loading}
                        title= "Cancel"
                        clickEvent={(e) => onCancelClick()}
                    />
                    <FilledBrightButton
                        disabled={props.customer.loading}
                        title= "Save Customer"
                        type="submit"
                        clickEvent={(e) => {}}
                    />
                </ModalFooter>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        customer: state.JobReducer.customer
    }
}

const mapDispachToProps = (dispatch) => ({
    submitCustomer: (data) => debouncedCreateCustomer(dispatch, data),
    editCustomer: (data, id) => debouncedEditCustomer(dispatch, data, id)
})

export default connect(mapStateToProps, mapDispachToProps)(CustomerModal)