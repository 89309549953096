import { createApiClient } from '@oneblinktech-org/helios-base';
import debounce from 'lodash.debounce';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, put, remove } = createApiClient({ apiUrl });

const headers = { 'Content-Type': 'application/json' }

export function vehicleMakes() {
    const token = localStorage.getItem('h-access_token')

    return get('/vehicle_makes', {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function createNewJob(payload) {
    const token = localStorage.getItem('h-access_token')
    
    return post('/jobs', payload, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        return e
    })
}

export function searchKey(payload) {
    const token = localStorage.getItem('h-access_token')
    
    return get('/jobs/search/' + payload, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        return e
    })
}

export function updateJob(payload) {
    const token = localStorage.getItem('h-access_token')
    return put('/jobs/' + payload.id, payload, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function getJobs(params) {
    const token = localStorage.getItem('h-access_token')
    let query = 'limit=' + (params.limit ? params.limit : '');
    if(params.searchText) {
        query += '&search=' + params.searchText
    }
    if(params.pageNum) {
        query += '&pageNum=' + params.pageNum
    }
    query += '&archived=' + params?.archived
    return get('/jobs?' + query, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function getPlanningJobs(payload) {
    const token = localStorage.getItem('h-access_token')

    return get('/jobs/planning/' + payload.startDay + '/' + payload.endDay, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function schedulePlanningJobs(payload) {
    const token = localStorage.getItem('h-access_token')

    return post('/jobs/scheduleJobs', {...payload}, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
        throw e
    })
}

export function getChooseJob(id) {
    const token = localStorage.getItem('h-access_token')

    return get('/jobs/' + id, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function delJob(id) {
    const token = localStorage.getItem('h-access_token')

    return remove('/jobs/' + id, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function createCustomer(payload) {
    const token = localStorage.getItem('h-access_token')

    return post('/users', {...payload}, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
        throw e
    })
}

export function editCustomer(payload,id) {
    const token = localStorage.getItem('h-access_token')

    return post(`/users/${id}`, {...payload}, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
        throw e
    })
}

export function getCustomer(payload) {
    const token = localStorage.getItem('h-access_token')

    return get(`/organizations/getCustomer${payload?.query ? payload.query : ''}`, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
        throw e
    })
}

export function getAssignableUsers() {
    const token = localStorage.getItem('h-access_token')

    return get("/organizations/getAllAssignableUsers", {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
        throw e
    })
}

export function getJobStatuses() {
    const token = localStorage.getItem('h-access_token')

    return get('/jobs/getJobsByStatus', {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
    throw e
    })
}

export function getJobByOrg() {
    const token = localStorage.getItem('h-access_token')

    return get('/jobs/getJobsByOrg', {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
    throw e
    })
}

export function getAssignedUsers() {
    const token = localStorage.getItem('h-access_token')

    return get('/jobs/getAssignedUsers', {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
    throw e
    })
}

export function getJobColorList() {
    const token = localStorage.getItem('h-access_token')

    return get('/jobs/getColorList', {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
        throw e
    })
}

export function getJobCustomerList() {
    const token = localStorage.getItem('h-access_token')

    return get('/jobs/filterOptions', {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
    throw e
    })
}

export function updateJobStatus(payload) {
    const token = localStorage.getItem('h-access_token')

    return put('/jobs/updateJobStatus/' + payload.jobId, { 'status_id': payload.statusId, itemOrders: payload.itemOrders }, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
    throw e
    })
}

export function uploadVehicleImgs(payload) {
    const token = localStorage.getItem('h-access_token')
    return post('/jobs/vehicleFiles/' + payload.id, payload.files, {
        "Authorization": `Bearer ` + token,
        "Content-Type": "multipart/form-data"
    }).then(result => {
        return result;
    }).catch(e => {
        throw e
    })
}

export function getVehicleImgs(id) {
    const token = localStorage.getItem('h-access_token')

    return get('/jobs/vehicleFiles/' + id, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
    throw e
    })
}