import React, { useEffect, useState, forwardRef } from 'react';
import {connect} from "react-redux";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Select, Modal, Tooltip, Skeleton, notification, Spin } from 'antd';
import DatePicker from 'react-datepicker'
import { formatPhoneNumber, lightOrDark, SUPPORT_URL } from '../../helpers/globals';
import { dateFormat, formatHours, formatNumber } from '../../helpers/stringFormat';
import { grayColor } from '../../helpers/globals';
import { saveCheck, plus } from '../../fakeData/svgFiles';
import {
    getAllAssignableUsers,
    createNewJob,
    getJobByOrg,
    unAssignJobColor,
    debouncedGetCustomers
} from '../../store/job/actions';
import { getNoteList } from '../../store/note/actions';
import AddNodes from '../modals/AddNodes';
import { FilledLightButton } from '@oneblinktech-org/helios-base';
import { createApiClient, ApiSpin, FilledBrightButton } from '@oneblinktech-org/helios-base';
import PhotoManagement from './photoManagement';
import Dots from '../Dots';
import Editable from '../Editable';
import Block from '../common/block';
import Customer from '../Customer';
import _set from 'lodash/set';
import DraftEditor from './DraftEditor';
import { differenceInMinutes } from 'date-fns'
import Lightbox from 'react-image-lightbox';
import ReactSelect from 'react-select'
import Avatar from '../Avatar';
import Pusher from 'pusher-js';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, put } = createApiClient({ apiUrl });
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: '$gray-darker',
        backgroundColor: '$blue-focus-bg'
    }),
    control: (provided) => ({
        ...provided,
        marginTop: "5%",
        backgroundColor: '$blue-focus-bg',
        color: '$gray-darker'
    }),
    singleValue: (provided) => ({
        ...provided,
        backgroundColor: '$blue-focus-bg',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: state.hasValue ? 'none' : ''
    })
}

const JobDetail = (props) => {
    const history = useHistory();
    const [isDark, setIsDark] = useState("dark");
    const jobStatuses = props.jobStatuses.data;
    const [assignableUsers, setAssignableUsers] = useState([]);
    const [jobItem, setJobItem] = useState(props?.item);
    const [changed, setChanged] = useState(false)
    const [noteOpen, setNoteOpen] = useState(false);
    const [archiveOpen, setArchiveOpen] = useState(false);
    const [replyNoteOpen, setReplyNoteOpen] = useState(false);
    const [singleNote, setSingleNote] = useState({})
    const [currentNoteIndex, setCurrentNoteIndex] = useState(0)
    const [focusField, setFocusField] = useState('')
    const Option = Select.Option;
    const [years, setYears] = useState([])
    const [activityOpen, setACtivityOpen] = useState(localStorage.getItem('activityOpen') ?? true)
    const [jobValue, setJobValue] = useState(jobItem?.value)
    const [jobInDate, setJobInDate] = useState(props?.item?.in_date)
    const [jobOutDate, setJobOutDate] = useState(props?.item?.out_date)
    const [jobArchiveAt, setJobArchiveAt] = useState(props?.item?.archived_at)
    const [showSendCustomerBtn, setShowSendCustomerBtn] = useState(false)
    const [showEditor, setShowEditor] = useState(false)
    const [comment, setComment] = useState('')
    const [plainComment, setPlainComment] = useState("")
    const [attachments, setAttachments] = useState([])
    const [isReset, setReset] = useState(false)
    const [isSaved, setSaved] = useState(false)
    const [lightBoxImages, setLightBoxImages] = useState([])
    const [imageIndex, setImageIndex] = useState(0)
    const [isOpenLightBox, setOpenLightBox] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [customer, setCustomer] = useState('')
    const [isUpdateCustomer, setUpdateCustomer] = useState(false)
    const isNewJob = Object.keys(jobItem).length === 0 || props.isNewJob

    useEffect(() => {
        var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_API_KEY}`, {
            cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
        });

        var orgChannel = pusher.subscribe("organizations");
        orgChannel.bind("choose-event", (data) => {
            if (data.event === "organization updated") {
                setUpdateCustomer(true)
            }
        });

        return (() => {
            pusher.unsubscribe('organizations')
        })
    }, []);

    useEffect(() => {
        if (Object.keys(props.customer.data).length > 0 && isUpdateCustomer && !props.customer.loading) {
            setJobItem((prev) => {
                const newData = {
                    ...prev,
                    customer: props.customer.data
                }
                return newData;
            })
            setUpdateCustomer(false)
         }
    },[isUpdateCustomer, props.customer.data, props.customer.loading])

    useEffect(() => {
        if (isNewJob) {
            props.debouncedGetCustomers()
            setCustomer(null)
        }
    }, [isNewJob])

    const [isAlertShow, setAlertShow] = useState(false)
    const [isAlertLoading, setAlertLoading] = useState(false)
    const [isError, setISError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {
        const years = range((new Date()).getFullYear(), 1970, -1)
        setYears(years)
        if (Object.keys( props?.jobStatuses.data)?.length === 0)
            props.getJobByOrg()
    }, [])

    useEffect(() => {
        if (props.assignableUsers.data) {
            setAssignableUsers([{ "id": -1, firstname: 'Unassigned', lastname: '' }, ...Object.values(props.assignableUsers.data)])
        }
    }, [props.assignableUsers.data])

    useEffect(() => {
        setJobItem(props.item)
        setJobValue(props.item?.value)
        props.getAllAssignableUsers()
        if (props?.item?.id) {
            props.getNoteList(props.item.id)
        }
    }, [props.item]);

    useEffect(() => {
        if (changed) {
            setTimeout(() => {
                props.createNewJob(jobItem, 1)
                setChanged(false)
            }, 500)
        }
    }, [changed]);

    useEffect(() => {
        const { isSuccess, message, isUpdating } = props?.newJob
        setAlertLoading(isUpdating)
        setIsSuccess(isSuccess)
        setISError(isSuccess === false)
        setErrorMsg(message)
    }, [props.newJob])

    const directEditJob = () => {
        setTimeout(function () {
            history.push(`/job/edit/` + jobItem.id)
        }, 1000)
    }

    const onOpenNote = (note, index) => {
        setNoteOpen(true)
        setSingleNote(note)
        setCurrentNoteIndex(index)
        if (!note.read_at) {
            try {
                const token = localStorage.getItem('h-access_token')

                get(`/notes/${note.id}/mark_read`, {
                    "Authorization": `Bearer ` + token
                }).then(result => {
                    if (result?.success == 'Mark as read') {
                        let changedNoteIndex = props.notes.findIndex(data => data.id == note.id);
                        if (changedNoteIndex !== -1) {
                            props.notes[changedNoteIndex].read_at = true
                        }

                        props.getJobByOrg()
                    }
                }).catch(e => {
                    return e
                })
            } catch (error) {
                console.error("Error sending data:", error);
            }
        }
    }

    const onMoveNote = (nextIndex) => {
        setCurrentNoteIndex(nextIndex)
        if (props?.notes[nextIndex]) {
            setSingleNote(props?.notes[nextIndex])
        }
    }

    const focusOutOnEnter = (key, value, field, mainFieldPath, defaultValue) => {
        const regx = /^[A-HJ-NPR-Z0-9]{17}$/g
        if (!regx.test(value) && field === 'vehicle_vin' && (key === 'Enter' || key === 'Tab') && value !== defaultValue) {
            notification.open({
                type: 'warning',
                message: 'Alert!',
                description: 'Please enter valid VIN number',
                onClick: () => {
                },
            });
            return;
        }
        if (key === 'Enter' || key === 'Tab' || key === 'blur') {
            if (value !== defaultValue) {
                setJobItem((prev) => {
                    const newData = { ...prev, [field]: field === 'value' && value === '' ? jobValue : value };
                    if (mainFieldPath)
                        _set(newData, mainFieldPath, value);
                    return newData;
                })
                if (!isNewJob) {
                    setChanged(true)
                    setAlertShow(true)
                }
            }
        }
        if (key === 'Enter')
            setFocusField('')
        if (field === 'value' && focusField === 'job_value' && key === 'Tab')
            setTimeout(() => setFocusField(''), 0);
    }

    const CustomInput = forwardRef(
        ({ value, onClick, label }, ref) => (
            <div onClick={onClick} ref={ref} className='item d-flex align-items-center justify-content-end gap-1' >
                <span className='item_label'>{label}:</span>
                <span className='item_date'>{value ? dateFormat(value, 'INOUT_DATE') : 'Not set'}</span>
            </div>
        ),
    );

    const changeInOutDate = (date, type) => {
        setAlertShow(true)
        setAlertLoading(true)
        switch(type) {
            case "IN":
                setJobInDate(date);
                break;
            case "OUT":
                setJobOutDate(date);
                break;
        }

        const token = localStorage.getItem('h-access_token')
    
        post('/jobs/updateDate/' + jobItem.id, {date:moment( date).format("YYYY-MM-DD"), type: type}, {
            "Authorization": `Bearer ` + token
        }).then(result => {
            setAlertLoading(false)
            setIsSuccess(true)
        }).catch(e => {
            setAlertLoading(false)
            setISError(true)
            setErrorMsg("Failed to set, please try again later.")
        })
    }

    const onArchive = () => {
        const token = localStorage.getItem('h-access_token')

        setArchiveOpen(true);

        get(`/jobs/${jobItem?.id}/${!jobArchiveAt ? 'archived' : 'unarchived'}`, {
            "Authorization": `Bearer ` + token
        }).then(result => {
            if(result) {
                notification.open({
                    type: 'success',
                    message: 'Alert!',
                    description: `Job successfully ${!jobArchiveAt ? 'archived' : 'unarchived'}`,
                    onClick: () => {
                    },
                });

                switch (result?.type) {
                    case 1:
                        setJobArchiveAt(result?.date)
                        props.onArchiveJob(0); // 0: archive, 1: unarchive
                        break;
                    case 2:
                        props.onArchiveJob(1); // 0: archive, 1: unarchive
                        setJobArchiveAt(null)
                        break;
                }

                setArchiveOpen(false)
            }
        }).catch(e => {
            notification.open({
                type: 'warning',
                message: 'Alert!',
                description: 'Failed to set, please try again later;',
                onClick: () => {
                },
            });
        });
    }

    const resetAllNoteState = () => {
        setReset(true)
        setShowEditor(false)
        setShowSendCustomerBtn(false)
        setComment('')
        setPlainComment("")
        setAttachments([])
    }

    const onAddNote = (sendToCustomer = false) => {
        if (!comment)
            return

        setLoading(true)
        const payload = {
            job_id: props.item.id,
            message: comment,
            file_ids: JSON.stringify(attachments?.map((file) => file?.id)),
            send_to_customer: sendToCustomer,
            sent_or_received: sendToCustomer ? "sent" : "saved",
            sender_id: props?.user?.data?.id
        }

        const token = localStorage.getItem('h-access_token')

        post('/notes', payload, {
            "Authorization": `Bearer ` + token
        }).then(result => {
            if (result.status === 201) {
                setSaved(true)
                if (result?.data?.message) {
                    openNotification("success", result?.data?.message)
                }
                resetAllNoteState()
                setTimeout(() => {
                    setSaved(false)
                }, 20000)
            } else {
                openNotification("warning", result?.data?.message)
            }
            setLoading(false)
        }).catch(e => {
            console.log(e, e?.response)
            setLoading(false)
            openNotification("error", e?.response?.data?.message ?? 'Failed to create note')
            return e
        })
    }

    const openNotification = (type, message) => {
        notification.open({
            type: type,
            message: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
            description: message,
            duration: 0,
        });
    }

    const createJob = (data) => {
        setCustomer(data)
        setJobItem((prev) => {
            const newData = { ...prev, customer: data }
            return newData
        })
        let createData = {
            customer_id: data.id,
            ...jobItem
        };
        props.createNewJob(createData, 0)
    }

    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

    return (
        <>
            <Spin spinning={props.newJob.isCreating ?? false} key={isNewJob} >
            <div className='expandcard-header'>
                {isAlertShow &&
                    <ApiSpin loading={isAlertLoading} error={isError} success={isSuccess} error_txt={errorMsg} support_url={SUPPORT_URL} />
                }
                <div className={`edit-number ${jobArchiveAt ? 'archived' : ''}`} onClick={() => setFocusField('ro_number')} onBlur={() => setFocusField('')}>
                    <div className='card-id'>
                            {!isNewJob && <span>#</span>}
                        {
                            !jobArchiveAt?
                            <Editable
                                type='text'
                                defaultValue={jobItem?.ro_number ?? ''}
                                onChange={(e) => {
                                    setJobItem({ ...jobItem, ro_number: e.target.value })
                                    if (!isNewJob) {
                                        setChanged(true)
                                        setAlertShow(true)
                                    }
                                }}
                                focusField={focusField === 'ro_number' || isNewJob}
                                autoFocus={true}
                                tabIndex={1}
                                placeholder='#'
                            />
                            :
                            <span>{jobItem?.ro_number}</span>
                        }
                    </div>
                    <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                </div>
                <div className='in-out_dates d-flex align-items-center justify-content-between gap-1'>
                    {
                        !jobArchiveAt &&
                        <>
                            <DatePicker
                                selected={jobInDate ? new Date(jobInDate) : null}
                                minDate={new Date(new Date().getFullYear() - 1, 0, 1)}
                                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
                                onChange={(date) => changeInOutDate(date, "IN")}
                                dateFormat="MMM d"
                                customInput={<CustomInput className="custom-input" label="IN" />}
                                withPortal
                            />
                            <DatePicker
                                selected={jobOutDate ? new Date(jobOutDate) : null}
                                minDate={jobInDate ? new Date(jobInDate) : new Date(new Date().getFullYear() - 1, 0, 1)}
                                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
                                onChange={(date) => changeInOutDate(date, "OUT")}
                                dateFormat="MMM d"
                                customInput={<CustomInput className="custom-input" label="OUT" />}
                                withPortal
                            />
                        </>
                        }
                        {!isNewJob &&
                            <div className='archive_btn d-flex align-items-center'>
                                <svg onClick={() => setArchiveOpen(true)} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7 11.609 3.009-3.01-.868-.867-1.523 1.523V5.591H6.382v3.664L4.86 7.732l-.87.868zM1.235 3.626v8.884q0 .111.072.183a.25.25 0 0 0 .182.07H12.51a.25.25 0 0 0 .183-.07.25.25 0 0 0 .07-.183V3.627zM1.552 14q-.615 0-1.084-.468Q0 13.064 0 12.448v-9.56q0-.265.085-.505.084-.24.254-.443L1.495.542q.203-.265.507-.403.304-.14.643-.139h8.694q.34 0 .647.139.309.138.511.403l1.164 1.414q.17.203.254.446.085.245.085.509v9.537q0 .616-.468 1.084T12.448 14zm.005-11.608h10.878l-.898-1.07a.3.3 0 0 0-.09-.063.3.3 0 0 0-.108-.024H2.653a.3.3 0 0 0-.107.024.3.3 0 0 0-.091.063z" fill="#0051BA" fillOpacity=".7" /></svg>
                                {
                                    archiveOpen &&
                                    (
                                        !jobArchiveAt ?
                                            <div className='archive_modal'>
                                                <h4>Archive this job?</h4>
                                                <div className='btn-group'>
                                                    <button className='archive_yes' onClick={() => onArchive()}>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7 11.609 3.009-3.01-.868-.867-1.523 1.523V5.591H6.382v3.664L4.86 7.732l-.87.868zM1.235 3.626v8.884q0 .111.072.183a.25.25 0 0 0 .182.07H12.51a.25.25 0 0 0 .183-.07.25.25 0 0 0 .07-.183V3.627zM1.552 14q-.615 0-1.084-.468Q0 13.064 0 12.448v-9.56q0-.265.085-.505.084-.24.254-.443L1.495.542q.203-.265.507-.403.304-.14.643-.139h8.694q.34 0 .647.139.309.138.511.403l1.164 1.414q.17.203.254.446.085.245.085.509v9.537q0 .616-.468 1.084T12.448 14zm.005-11.608h10.878l-.898-1.07a.3.3 0 0 0-.09-.063.3.3 0 0 0-.108-.024H2.653a.3.3 0 0 0-.107.024.3.3 0 0 0-.091.063z" fill="#fff" /></svg>
                                                        <span>YES</span>
                                                    </button>
                                                    <button onClick={() => setArchiveOpen(false)} className='archive_no'>NO</button>
                                                </div>
                                                <div className='description'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.477 4.476 0 10 0c5.523 0 10 4.476 10 10 0 5.523-4.476 10-10 10-5.523 0-10-4.476-10-10m11-.863q0-.54-.381-.843-.38-.303-.967-.302-.585 0-.952.302-.367.303-.367.902v5.77q0 .59.367.908.366.318.952.318.585 0 .967-.31.381-.311.381-.917zM9.681 6.324q.538 0 .928-.397T11 4.823q0-.728-.4-1.109a1.3 1.3 0 0 0-.919-.38q-.537 0-.942.42t-.406 1.07q0 .716.396 1.108t.952.392" fill="#959393" /></svg>
                                                    <span>You can always find it by going to the Jobs &gt; List page in the main menu.</span>
                                                </div>
                                            </div>
                                            :
                                            <div className='archive_modal'>
                                                <div onClick={() => onArchive()} className='unarchive_wrap d-flex gap-3 align-items-center justify-content-start'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9 7.289-3.654 3.653L6.4 11.996l1.85-1.85v4.45h1.5v-4.45l1.85 1.85 1.054-1.054zM2 4.904v10.788a.3.3 0 0 0 .087.222.3.3 0 0 0 .22.086h13.385a.3.3 0 0 0 .222-.086.3.3 0 0 0 .086-.222V4.904zM2.385 17.5q-.75 0-1.317-.568Q.5 16.362.5 15.615V4.005q0-.32.103-.612.103-.291.309-.537l1.404-1.698q.245-.321.615-.49Q3.3.5 3.71.5h10.56q.412 0 .786.168t.62.49l1.413 1.717q.206.246.309.542t.103.618v11.58q0 .75-.568 1.317-.57.568-1.317.568zM2.39 3.404H15.6l-1.09-1.298a.4.4 0 0 0-.111-.077.3.3 0 0 0-.13-.029H3.721a.3.3 0 0 0-.13.029.4.4 0 0 0-.11.077z" fill="#73D19D" /></svg>
                                                    <span>Unarchive</span>
                                                </div>
                                                <svg onClick={() => setArchiveOpen(false)} className='close' width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.25 1.41 7.59.75 4.5 3.828 1.41.75l-.66.66L3.828 4.5.75 7.59l.66.66L4.5 5.172 7.59 8.25l.66-.66L5.172 4.5z" fill="#3F3B3B" /></svg>
                                            </div>
                                    )
                                }
                            </div>
                        }
                </div>
            </div>
            <div className='section' >
                <div className="d-flex flex-column jobdetail justify-content-between">
                    <div className='customer-details'>
                            <div className="info-row">
                            {isNewJob && !jobItem.customer?
                                    <div className='add-customer-row'>
                                        <div className="flex-grow-1">
                                            <ReactSelect
                                                onChange={(option) => createJob(option)}
                                                options={Object.keys(props.customers.data)?.length > 0 ? props.customers.data : []}
                                                isSearchable={true}
                                                isClearable={true}
                                                styles={customStyles}
                                                name="customer"
                                                className="select-box-bottom-line"
                                                value={customer}
                                                placeholder="Search for existing customer..."
                                                autoComplete="vin"
                                                getOptionLabel={e => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={e.icon_url} alt="" className="mr-2" />
                                                        <span style={{ marginLeft: 5 }}>{(e.firstname && e.lastname) ? e.firstname + " " + e.lastname : ''}</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <span className='label-or'>OR</span>
                                        <Customer customerData={jobItem?.customer}>
                                            <FilledBrightButton
                                                svgFile={plus}
                                                title="Add New Customer"
                                                clickEvent={(e) => { }}
                                            />
                                        </Customer>
                                    </div>
                                    :
                            <Customer customerData={jobItem?.customer}>
                                <div className="edit-info">
                                    <b className='sf-pro-display-bold'>{jobItem.customer ? `${jobItem.customer.lastname} ${jobItem.customer.firstname}` : ''}</b>
                                    <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                </div>
                            </Customer>
                                     }
                                {!isNewJob &&
                                    <div className="edit-info d-flex flex-row">
                                        <span className='d-sm-none'>{jobItem?.customer?.address ?? '-'}</span>
                                        <div>
                                            <a href={`tel:${jobItem?.customer?.helios_user_setting?.phone_number}`} className='d-flex align-items-center'>
                                                {jobItem?.customer?.helios_user_setting?.phone_number && <img className='phone-svg d-flex' alt="" src={require('../../assets/images/phone.svg').default} />}
                                                {jobItem?.customer?.helios_user_setting?.phone_number && <span>{formatPhoneNumber(jobItem?.customer?.helios_user_setting?.phone_number)}</span>}
                                            </a>
                                            {jobItem?.customer?.helios_user_setting?.alternate_phone &&
                                                <a href={`tel:${jobItem?.customer?.helios_user_setting?.alternate_phone}`} className='d-flex align-items-center'>
                                                    {jobItem?.customer?.helios_user_setting?.alternate_phone && <img className='phone-svg d-flex' alt="" src={require('../../assets/images/phone.svg').default} />}
                                                    {jobItem?.customer?.helios_user_setting?.alternate_phone && <span>{formatPhoneNumber(jobItem?.customer?.helios_user_setting?.alternate_phone)}</span>}
                                                </a>
                                            }
                                        </div>
                                    </div>
                                }
                        </div>
                        <div className="info-row d-none d-sm-flex">
                            <span>Address</span>
                            <div className="edit-info">
                                <span className='border-0'>{jobItem?.customer?.address ?? '-'}</span>
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label' >Year / Make / Model</span>
                            <div className="edit-info" >
                                {
                                    !jobArchiveAt &&
                                    <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                }
                                <div className='edit-content'>
                                    <div onClick={() => setFocusField('vehicle_year')} onFocus={() => setFocusField('vehicle_year')}>
                                        {
                                            !jobArchiveAt ?
                                            <Editable
                                                type='select'
                                                defaultValue={jobItem?.vehicle?.year}
                                                onChange={(e) => {
                                                    setJobItem((prev) => ({
                                                        ...prev,
                                                        vehicle_year: e,
                                                        vehicle: {
                                                            ...prev?.vehicle,
                                                            year: e
                                                        }
                                                    }))
                                                    if (!isNewJob) {
                                                        setChanged(true)
                                                        setAlertShow(true)
                                                    }
                                                }}
                                                focusField={focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year' || isNewJob}
                                                options={years.map((i) => { return { value: i, label: i } })}
                                                tabIndex={2}
                                                size={80}
                                                placeholder='Year'
                                            />
                                            :
                                            <span>{jobItem?.vehicle?.year}</span>
                                        }
                                    </div>
                                    <span>{focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year' ? '/' : ''}</span>
                                    <div onClick={() => setFocusField('vehicle_make_id')} onFocus={() => setFocusField('vehicle_make_id')}>
                                        {
                                            !jobArchiveAt ?
                                            <Editable
                                                type='select'
                                                defaultValue={jobItem?.vehicle?.make?.name}
                                                onChange={(e) => {
                                                    setJobItem((prev) => ({
                                                        ...jobItem,
                                                        vehicle_make_id: e,
                                                        vehicle: {
                                                            ...prev?.vehicle,
                                                            make: {
                                                                ...prev?.vehicle?.make,
                                                                name: e
                                                            }
                                                        }
                                                    }))
                                                    if (!isNewJob) {
                                                        setChanged(true)
                                                        setAlertShow(true)
                                                    }
                                                }}
                                                focusField={focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year' || isNewJob}
                                                options={props?.vehicleMakes?.data && Object.keys(props?.vehicleMakes?.data)?.length > 0 ? props?.vehicleMakes?.data?.map((i) => { return { value: i.id, label: i.name } }) : []}
                                                tabIndex={3}
                                                size={130}
                                                placeholder='Make'
                                            />
                                            :
                                            <span>{jobItem?.vehicle?.make?.name}</span>
                                        }
                                    </div>
                                    <span>{focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year' ? '/' : ''}</span>
                                    <div className='model-field' onClick={() => setFocusField('vehicle_model')} onFocus={() => setFocusField('vehicle_model')}>
                                        {
                                            !jobArchiveAt ?
                                            <Editable
                                                type='text'
                                                defaultValue={jobItem?.vehicle?.model}
                                                focusField={focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year' || isNewJob}
                                                autoFocus={focusField === 'vehicle_model'}
                                                size={60}
                                                onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_model', 'vehicle.model', jobItem?.vehicle?.model ?? '')}
                                                tabIndex={4}
                                                placeholder='Model'
                                            />
                                            :
                                            <span>{jobItem?.vehicle?.model}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Insurance Company</span>
                            <div className="edit-info" onClick={() => setFocusField('insurance_company')} onFocus={() => setFocusField('insurance_company')}>
                                {
                                    !jobArchiveAt ?
                                    <>
                                        <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                        <Editable
                                            type='text'
                                            defaultValue={jobItem?.insurance_company}
                                            focusField={focusField === 'insurance_company' || isNewJob}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'insurance_company', '', jobItem?.insurance_company ?? '')}
                                            autoFocus={true}
                                            tabIndex={5}
                                            size={180}
                                            placeholder='Type your Insurance company'
                                        />
                                    </>
                                    :
                                    <span>{jobItem?.insurance_company}</span>
                                }
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Color / Paint Code</span>
                            <div className="edit-info" >
                                {
                                    !jobArchiveAt ?
                                    <>
                                        <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                        <div className='edit-content'>
                                            <div onClick={() => setFocusField('color')} onFocus={() => setFocusField('color')}>
                                                <Editable
                                                    type='text'
                                                    defaultValue={jobItem?.vehicle?.exterior_color}
                                                    focusField={focusField === 'color' || focusField === 'paint_code' || isNewJob}
                                                    autoFocus={focusField === 'color'}
                                                    onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_exterior_color', 'vehicle.exterior_color', jobItem?.vehicle?.exterior_color ?? '')}
                                                    tabIndex={6}
                                                    placeholder='xxxxxxx'
                                                />
                                            </div>
                                            <span>{'/'}</span>
                                            <div onClick={() => setFocusField('paint_code')} onFocus={() => setFocusField('paint_code')}>
                                                <Editable
                                                    type='text'
                                                    defaultValue={jobItem?.vehicle?.paint_code}
                                                    focusField={focusField === 'color' || focusField === 'paint_code' || isNewJob}
                                                    autoFocus={focusField === 'paint_code'}
                                                    onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_paint_code', 'vehicle.paint_code', jobItem?.vehicle?.paint_code ?? '')}
                                                    tabIndex={7}
                                                    placeholder='000 /0000'
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span>{jobItem?.vehicle?.exterior_color}</span>
                                        <span>{'/'}</span>
                                        <span>{jobItem?.vehicle?.paint_code}</span>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>License Plate</span>
                            <div className="edit-info" onClick={() => setFocusField('license')} onFocus={() => setFocusField('license')}>
                                {
                                    !jobArchiveAt ?
                                    <>
                                        <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                        <Editable
                                            type='text'
                                            defaultValue={jobItem?.vehicle?.license_plate_number}
                                            focusField={focusField === 'license' || isNewJob}
                                            size={90}
                                            maxLength={10}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_license_plate_number', 'vehicle.license_plate_number', jobItem?.vehicle?.license_plate_number ?? '')}
                                            autoFocus={true}
                                            tabIndex={8}
                                            placeholder='Type your licence plate'
                                        />
                                    </>
                                    :
                                    <span>{jobItem?.vehicle?.license_plate_number}</span>
                                }
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>VIN</span>
                            <div className="edit-info" onClick={() => setFocusField('vin')} onFocus={() => setFocusField('vin')}>
                                {
                                    !jobArchiveAt ?
                                    <>
                                        <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                        <Editable
                                            type='text'
                                            defaultValue={jobItem?.vehicle?.vin}
                                            focusField={focusField === 'vin' || isNewJob}
                                            size={240}
                                            maxLength={17}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_vin', 'vehicle.vin', jobItem?.vehicle?.vin ?? '')}
                                            autoFocus={true}
                                            tabIndex={9}
                                            placeholder='Type your VIN'
                                        />
                                    </>
                                    :
                                    <span>{jobItem?.vehicle?.vin}</span>
                                }
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Trim Code</span>
                            <div className="edit-info" onClick={() => setFocusField('trim')} onFocus={() => setFocusField('trim')}>
                                {
                                    !jobArchiveAt ?
                                    <>
                                        <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                        <Editable
                                            type='text'
                                            defaultValue={jobItem?.vehicle?.trim_code}
                                            focusField={focusField === 'trim' || isNewJob}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_trim_code', 'vehicle.trim_code', jobItem?.vehicle?.trim_code ?? '')}
                                            autoFocus={true}
                                            tabIndex={10}
                                            placeholder='Type your Trim code'
                                        />
                                    </>
                                    :
                                    <span>{jobItem?.vehicle?.trim_code}</span>
                                }
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Frame Hours / Paint Hours</span>
                            <div className="edit-info">
                                {
                                    !jobArchiveAt ?
                                    <>
                                        <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                        <div className='edit-content'>
                                            <div onClick={() => setFocusField('frame_hours')} onFocus={() => setFocusField('frame_hours')} >
                                                <Editable
                                                    type='text'
                                                    defaultValue={isNewJob ? '' : jobItem?.frame_hours !== null ? formatHours(jobItem?.frame_hours, '') : ''}
                                                    focusField={focusField === 'frame_hours' || focusField === 'paint_hours' || isNewJob}
                                                    size={35}
                                                    onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'frame_hours', '', jobItem?.frame_hours ?? '')}
                                                    autoFocus={focusField === 'frame_hours'}
                                                    tabIndex={11}
                                                    postFix='h'
                                                    placeholder='00h'
                                                />
                                            </div>
                                            <span>{'/'}</span>
                                            <div onClick={() => setFocusField('paint_hours')} onFocus={() => setFocusField('paint_hours')}>
                                                <Editable
                                                    type='text'
                                                    defaultValue={isNewJob ? '' : jobItem?.paint_hours !== null ? formatHours(jobItem?.paint_hours, '') : ''}
                                                    focusField={focusField === 'frame_hours' || focusField === 'paint_hours' || isNewJob}
                                                    size={35}
                                                    onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'paint_hours', '', jobItem?.paint_hours ?? '')}
                                                    autoFocus={focusField === 'paint_hours'}
                                                    tabIndex={12}
                                                    postFix='h'
                                                    placeholder='00h'
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span>{jobItem?.frame_hours !== null ? formatHours(jobItem?.frame_hours, '') : ''}</span>
                                        <span>{'/'}</span>
                                        <span>{jobItem?.paint_hours !== null ? formatHours(jobItem?.paint_hours, '') : ''}</span>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Job value</span>
                            <div className="edit-info" onClick={() => setFocusField('job_value')} onFocus={() => setFocusField('job_value')}>
                                {
                                    !jobArchiveAt ?
                                    <>
                                        <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                        <Editable
                                            type='value'
                                            defaultValue={isNewJob ? '' : jobItem?.value !== null ? formatNumber(jobItem?.value, 2, '') : ''}
                                            focusField={focusField === 'job_value' || isNewJob}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, jobValue, 'value', '', jobItem?.value ?? '')}
                                            onChange={(e) => setJobValue(e.floatValue)}
                                            onBlur = {(e) => focusOutOnEnter(e.type, jobValue, 'value', '', jobItem?.value ?? '')}
                                            autoFocus={focusField === 'job_value'}
                                            tabIndex={13}
                                            preFix='$'
                                            size={'50%'}
                                        />
                                    </>
                                    :
                                    <span>{jobItem?.value !== null ? formatNumber(jobItem?.value, 2, '') : ''}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <Modal
                        className="note_modal"
                        centered
                        open={noteOpen}
                        onCancel={() => {
                            setNoteOpen(false)
                            props.replyBack(false)
                        }}
                        footer={null}
                        header={null}
                    >
                        <div className="note_wrap">
                            <div className="note_header">
                                <span>FROM</span>
                                <span>METHOD</span>
                                <span>MESSAGE</span>
                                <span>DATE</span>
                            </div>
                            <div className="note_content">
                                <span>{singleNote?.sender?.firstname}</span>
                                <span><img src={require('../../assets/images/sms-down.png').default} /></span>
                                <span>{singleNote?.message}</span>
                                <span>{dateFormat(singleNote?.created_at, 'TXT_YEAR_DATE')}</span>
                            </div>
                            <div className="note_footer">
                                <div>
                                    <div onClick={() => onMoveNote(currentNoteIndex == (props?.notes?.length - 1) ? currentNoteIndex : currentNoteIndex + 1)} className="note_previus">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M13.178 6.278H2.846L5.95 9.172a.752.752 0 0 1 .245.55.752.752 0 0 1-.245.55.865.865 0 0 1-.59.228.865.865 0 0 1-.589-.228L.244 6.05a.742.742 0 0 1 0-1.1L4.77.728A.862.862 0 0 1 5.36.5c.214 0 .427.076.59.228a.752.752 0 0 1 .244.55.752.752 0 0 1-.244.55L2.846 4.722h10.332c.456.007.822.353.822.778 0 .425-.366.772-.822.778Z" fill={currentNoteIndex < (props?.notes?.length - 1) ? "#0051BA" : "#EAEAEA"} fillOpacity=".7" /></svg>
                                        <span className={currentNoteIndex < (props?.notes?.length - 1) ? "" : "disabled"}>Previous</span>
                                    </div>
                                    <div className="note_reply" onClick={() => props.replyBack(true, currentNoteIndex, props?.notes[currentNoteIndex])}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path d="M12.765 10.5V7.84c0-.683-.262-1.265-.784-1.748-.523-.482-1.154-.724-1.893-.724H2.366l3.17 2.927-.88.801L0 4.798 4.656.5l.88.801-3.17 2.927h7.722c1.081 0 2.004.352 2.767 1.057C13.618 5.99 14 6.841 14 7.84v2.66h-1.235Z" fill="#5D6F88" /></svg>
                                        <span>Reply</span>
                                    </div>
                                </div>
                                <div>
                                    <div onClick={() => onMoveNote(currentNoteIndex == 0 ? 0 : currentNoteIndex - 1)} className="note_next-message">
                                        <span className={currentNoteIndex == 0 ? "disabled" : ""}>Next message</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M.821 6.278h10.333L8.05 9.172a.752.752 0 0 0-.245.55.75.75 0 0 0 .245.55.865.865 0 0 0 .59.228.865.865 0 0 0 .589-.228l4.526-4.222a.742.742 0 0 0 0-1.1L9.23.728A.862.862 0 0 0 8.64.5a.861.861 0 0 0-.59.228.752.752 0 0 0-.244.55c0 .206.088.404.244.55l3.104 2.894H.82C.366 4.73 0 5.075 0 5.5c0 .425.366.772.821.778Z" fill={currentNoteIndex == 0 ? "#EAEAEA" : "#0051BA"} fillOpacity=".7" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {replyNoteOpen &&
                        <Modal
                            className="note-modal"
                            open={replyNoteOpen}
                            footer={null}
                            width={492}
                            onOk={() => { }}
                            onCancel={() => { }}
                            closeIcon={
                                <img
                                    className="modal-close"
                                    alt=""
                                    src={require("../../assets/images/production_board/modal-close.svg").default}
                                />
                            }
                            centered
                        >
                            <AddNodes currentUser={props.currentUser} jobItem={jobItem} jobId={jobItem.id} />
                        </Modal>
                    }
                </div>
                <PhotoManagement
                    currentNoteIndex={props.currentNoteIndex}
                    currentNote={props.currentNote}
                    currentUser={props.currentUser}
                    item={props.item}
                    jobArchiveAt={jobArchiveAt}
                    setAttachments={setAttachments}
                    setShowEditor={setShowEditor}
                    isReset={isReset}
                    setReset={setReset}
                />
                <div className='section-action'>
                    <div className="section-item">
                        <label>Status:</label>
                        {
                            !jobArchiveAt ?
                            <Select
                                className='item'
                                tabIndex={14}
                                size='large'
                                placeholder="Select status"
                                value={jobItem?.status_id || undefined}
                                onChange={(item) => {
                                    setJobItem({ ...jobItem, status_id: item })
                                    if (!isNewJob) {
                                        setChanged(true)
                                        setAlertShow(true)
                                    }
                                }}
                            >
                                {Object.keys(props.jobStatuses.data).map((key, index) => {
                                    return (
                                        <Option key={index} value={parseInt(jobStatuses[key].id)}>
                                            {jobStatuses[key].name}
                                        </Option>
                                    )
                                })
                                }
                            </Select>
                            :
                            <span>Archived</span>
                        }
                    </div>

                    <div className="section-item">
                        <label>Assigned to:</label>
                        {
                            !jobArchiveAt ?
                            <Select
                                tabIndex={15}
                                size='large'
                                placeholder="Select user"
                                value={jobItem.assigned_to_user_id || -1}
                                onChange={(item) => {
                                    setJobItem({ ...jobItem, assigned_to_user_id: item })
                                    if (!isNewJob) {
                                        setChanged(true)
                                        setAlertShow(true)
                                    }
                                }}
                                className="assign-user-select item"
                            >
                                {assignableUsers?.map((user, index) => {
                                    return (
                                        <Option key={index} value={user?.id}>
                                            {user?.firstname} {user?.lastname}
                                        </Option>
                                    )
                                })}
                            </Select>
                            :
                            <span>{jobItem?.assigned_to_user?.firstname} {jobItem?.assigned_to_user?.lastname}</span>
                        }
                    </div>
                    <div className="section-item">
                        <label>Card color:</label>
                        <div className='d-flex flex-row gap-2 justify-content-between item'>
                            {props.colorList.data.map((item, index) => {
                                // Check if the current item is the selected color
                                const isSelected = jobItem?.color_id === item.id;

                                return (
                                    <div
                                        key={index}
                                        className='colour-card'
                                        style={{
                                            border: `4px solid ${isSelected ? item.color : '#fff'}`
                                        }}
                                        onClick={() => {
                                            if (isSelected) {
                                                props.changeBackground(null);
                                                setJobItem({ ...jobItem, color_id: null, color: null });
                                                setChanged(true);
                                            } else {
                                                props.changeBackground(item);
                                                setJobItem({ ...jobItem, color_id: item.id, color: item?.color });
                                                setChanged(true);
                                            }
                                        }}>
                                        <div className='card-left' style={{ backgroundColor: item?.color }} />
                                        <div className='linegroup'>
                                            <div className='rectangleParent'>
                                                <div className='frameChild' />
                                                <div className='frameChild' />
                                            </div>
                                            <div className='rectangleParent'>
                                                <div className='frameInner' />
                                                <div className='frameChild' />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>

                    <div className="section-item">
                        <label>dots:</label>
                        <Dots className="item" defaultValue={jobItem?.dots_bits ? Array.from(jobItem.dots_bits) : []} jobData={jobItem} isFilter={false}
                            onChange={(value) => {
                                setJobItem({ ...jobItem, dots_bits: value })
                                setAlertShow(false)
                            }}
                        />
                    </div>
                </div>
                <div className='activity-section'>
                    <Block
                        title='Activity'
                        isOpen={activityOpen}
                        onToggle={() => {
                            let activityOpenN = !activityOpen
                            localStorage.setItem('activityOpen', activityOpenN)
                            setACtivityOpen(activityOpenN)
                        }}
                    >
                        <div className='note-placeholder mt-1 w-100'>
                            <div className='add-note-frame' >
                                <div className='comment-section'>
                                    <img className='user-image' src={Object.keys(props?.user?.data)?.length > 0 ? props.user?.data?.helios_user_setting?.avatar : require('../../assets/images/skel-user.png').default} alt='user' />
                                    <div className='comment-content' >
                                        {showEditor ?
                                            <DraftEditor setComment={setComment} setPlainComment={setPlainComment} />
                                            : <div className='input-section' onClick={() => {
                                                setSaved(false)
                                                setShowEditor(true)
                                            }}>
                                                <span>Add a comment...</span>
                                            </div>
                                        }
                                        <div className='d-flex flex-row flex-wrap gap-2'>
                                            {attachments?.map((attachment, index) => (
                                                <img
                                                    key={index}
                                                    alt={attachment.media_name}
                                                    src={attachment.media_type === 'pdf' ? require('../../assets/images/pdf-icon.png').default : attachment?.media_path}
                                                    className='attachment' />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='note-buttons'>
                                        <div className='d-flex' >
                                            {!isSaved &&
                                                <button
                                                    className={`btn-save ${plainComment?.length < 4 && 'disable'}`}
                                                    disabled={plainComment?.length < 4 || isLoading}
                                                    onClick={() => onAddNote(false)}
                                                >  {isLoading ? <Spin size='small' /> : `Save `}</button>
                                            }
                                            {!isSaved &&
                                                <button className={`btn-arrow ${plainComment?.length < 4 && 'disable'}`}
                                                    disabled={plainComment?.length < 4 || isLoading}
                                                    onClick={() => setShowSendCustomerBtn((show) => !show)}
                                                >
                                                    <img alt="" src={require('../../assets/images/line.svg').default} />
                                                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="Icon Color" fill-rule="evenodd" clip-rule="evenodd" d="M12.5 1.57692L11.4091 0.5L6.5 5.34615L1.59091 0.5L0.5 1.57692L6.5 7.5L12.5 1.57692Z" fill="white" />
                                                    </svg>
                                                </button>
                                            }
                                            {isSaved &&
                                                <div className='d-flex' >
                                                    <button className={`btn-save btn-saved`} disabled={true}>{`Saved and Sent!`}</button>
                                                </div>
                                            }
                                            {showEditor &&
                                                <div className='close-btn' onClick={resetAllNoteState} >
                                                    <img alt="" src={require('../../assets/images/production_board/modal-close.svg').default} />
                                                </div>
                                            }
                                        </div>
                                        {showSendCustomerBtn &&
                                            <div className='d-flex justify-content-start w-100'>
                                                <button
                                                    className='send-customer'
                                                    onClick={() => onAddNote(true)}
                                                >
                                                    Save and Send to Customer
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {props.notes.loading ?
                                <Skeleton active />
                                :
                                <div className='notes'>
                                    {props.notes.length > 0 &&
                                        props.notes.map((note, index) => {
                                            const now = new Date();
                                            const difference = differenceInMinutes(now, new Date(note.created_at));
                                            return (
                                                <div key={index} className='note-item'>
                                                    <div className='d-flex flex-row gap-2' >
                                                        <Avatar
                                                            className='sender-avatar'
                                                            imgClass='sender-avatar'
                                                            userImage={note?.sender?.helios_user_setting?.avatar}
                                                            userName={note?.sender}
                                                        />
                                                        <div className='d-flex flex-row gap-2 align-items-baseline'>
                                                            <span className='name'>{note?.sender?.firstname} {note?.sender?.lastname?.substring(0, 1)}.,</span>
                                                            <Tooltip title={moment(note.created_at).format("dddd MMMM DD, YYYY [at] hh:mma")} >
                                                                <span className='time'>{`${note?.sent_or_received} ${difference < 5 ? 'just now' : dateFormat(note.created_at, 'TXT_DATE')}`}</span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className='note-message' dangerouslySetInnerHTML={{ __html: note.message }} />
                                                    <div className='d-flex flex-wrap gap-2'>
                                                        {note?.images && note?.images?.map((image, img_index) => {
                                                            return (
                                                                <div key={img_index} onClick={() => {
                                                                    setLightBoxImages(note?.images)
                                                                    setImageIndex(img_index)
                                                                    setOpenLightBox(true)
                                                                }}>
                                                                    {image.media_type === "pdf" ?
                                                                        <img className='note-attachement' src={require('../../assets/images/pdf-icon.png').default} />
                                                                    :
                                                                        <img className='note-attachement' src={image?.media_path} />
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            }
                        </div>
                    </Block>
                </div>
            </div>
            </Spin>
            {/* light box images for notes */}
            {isOpenLightBox &&
                <Lightbox
                    mainSrc={lightBoxImages[imageIndex]?.media_path}
                    nextSrc={lightBoxImages[(imageIndex + 1) % lightBoxImages?.length]['media_path']}
                    prevSrc={lightBoxImages[(imageIndex + lightBoxImages.length - 1) % lightBoxImages.length]['media_path']}
                    onCloseRequest={() => setOpenLightBox(false)}
                    onMovePrevRequest={() =>
                        setImageIndex((imageIndex + lightBoxImages.length - 1) % lightBoxImages.length)
                    }
                    onMoveNextRequest={() =>
                        setImageIndex((imageIndex + 1) % lightBoxImages.length)
                    }
                    imageLoadErrorMessage={
                        <di>{lightBoxImages[imageIndex]['media_type'] === 'pdf' ?
                            <iframe className='lightbox-iframe' title='pdf' src={lightBoxImages[imageIndex]['media_path']} ></iframe>
                            : 'This image failed to load'}
                        </di>
                    }
                    enableZoom={lightBoxImages[imageIndex]['media_type'] === 'pdf' ? false : true}
                />
            }
            {
                archiveOpen &&
                <div className='archive-modal_overlay' onClick={() => setArchiveOpen(false)}></div>
            }
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        jobStatuses: state.JobReducer.jobStatuses,
        colorList: state.JobReducer.colorList,
        assignableUsers: state.JobReducer.assignableUsers,
        notes: state.NoteReducer.notes?.data,
        vehicleMakes: state.JobReducer.vehicleMakes,
        user: state.UserReducer.user,
        newJob: state.JobReducer.newJob,
        customers: state.JobReducer.customers,
        customer: state.JobReducer.customer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJobByOrg: () => dispatch(getJobByOrg()),
        getAllAssignableUsers: () => dispatch(getAllAssignableUsers()),
        createNewJob: (data, flag) => dispatch(createNewJob(data, flag)),
        getNoteList: (jobId) => dispatch(getNoteList(jobId)),
        debouncedGetCustomers: () => debouncedGetCustomers(dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(JobDetail);